const variables = {
  preplixPrimary: "#D10724",
};

export const styles = {
  pagination: {
    "& .Mui-selected": {
      backgroundColor: `${variables.preplixPrimary} !important`,
      color: "white",
    },
  },
  checkbox: {
    color: variables.preplixPrimary,
    "&.Mui-checked": {
      color: variables.preplixPrimary,
    },
  },
};
