import {
  AlertColor,
  Box,
  Checkbox,
  Container,
  Grid2 as Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import PageLoader from "../../../components/PageLoader";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Notification from "../../../components/Notification";
import { CreateInvoiceForm } from "./CreateInvoiceForm";
import { FormModel } from "../../../components/Form";
import RenderFormFields from "../../../components/Form/helper";
import PrimaryButton from "../../../components/PrimaryButton";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { IconButton } from "@mui/material";
import { URLS } from "../../../utils/constants/urls";
import axiosInstance from "../../../utils/axios";
import { logout } from "../../../utils/redux";
import { routes } from "../../../utils/constants/routes";
import { Validators } from "../../../utils/validators";
import { styles } from "./styles";
import { FranchiseId } from "../../../utils/redux/reducer/authentication-slice";

const plansDropdown = {
  label: "",
  value: "",
  size: "small",
  type: "drop-down",
  typeValue: "text",
  variant: "outlined",
  placeholder: "Please select customer type",
  field: "id",
  validators: [],
  responsive: { xs: 10 },
  required: true,
  sx: {
    height: "40px",
    backgroundColor: "#FFFFFF",
    marginBottom: 0,
    borderRadius: "15px",
    border: "1px solid #FFA7B7",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  inputProps: {
    sx: {
      border: "none",
      padding: "15px",
      paddingLeft: "15px",
    },
  },
  hiddenLabel: true,
};

const customInputField = {
  label: "",
  value: "",
  size: "small",
  type: "text",
  typeValue: "text",
  variant: "outlined",
  placeholder: "",
  field: "amount",
  validators: [
    { check: Validators.required, message: "This field is mandatory" },
  ],
  responsive: { xs: 10 },
  required: true,
  sx: {
    height: "40px",
    backgroundColor: "#FFFFFF",
    marginBottom: 0,
    borderRadius: "15px",
    border: "1px solid #FFA7B7",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  inputProps: {
    sx: {
      border: "none",
      padding: "10px",
      paddingLeft: "15px",
    },
  },
  hiddenLabel: true,
};

interface InvoiceData {
  customerType: string;
  gstIn: string;
  invoiceDate: string;
  paymentMethod: string;
  userId: string | number;
}

interface ErrorState {
  customerType: boolean;
  gstIn: boolean;
  invoiceDate: boolean;
  paymentMethod: boolean;
  userId: boolean;
}

interface DropdownType {
  label: string;
  value: string;
}

interface SubscriptionPlan {
  amount: string;
  discount: string;
  id: number | string;
  name: string;
  taxes: string;
  total: string;
}

const CreateInvoice = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();

  const franchise_id = useSelector(FranchiseId);

  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState({
    display: false,
    severity: "",
    message: "",
  });
  const [invoiceForm, setInvoiceForm] = useState<InvoiceData>();
  const [customerTypesDropdown, setCustomerTypesDropdown] = useState<
    DropdownType[]
  >([]);
  const [errorState, setErrorState] = useState<ErrorState>({
    customerType: true,
    gstIn: true,
    invoiceDate: true,
    paymentMethod: true,
    userId: true,
  });
  const [itemData, setItemData] = useState<
    {
      id: string | number;
      name: string;
      indexId: number;
      amount: string;
      discount: string;
      total: string;
    }[]
  >([]);
  const [usersDropdown, setUsersDropdown] = useState<DropdownType[]>([]);
  const [subscriptionPlans, setSubscriptionPlans] = useState<
    SubscriptionPlan[]
  >([]);
  const [subscriptionDropdown, setSubscriptionDropdown] = useState<
    DropdownType[]
  >([]);
  const [handleIndex, setHandleIndex] = useState(0);
  const [customAmount, setCustomAmount] = useState<boolean>(false);
  const [customAmountPaid, setCustomAmountPaid] = useState<string>("");

  const closeNotification = (value: boolean) => {
    setMessage((prevState) => ({ ...prevState, display: value }));
  };

  const setActionMessage = (display = false, severity = "", message = "") => {
    setMessage({
      display,
      severity,
      message,
    });
  };

  const getCustomerTypes = useCallback(async () => {
    try {
      setLoading(true);

      const {
        data,
      }: {
        data: { id: string; name: string }[];
      } = await axiosInstance(URLS.getCustomerTypes);

      const createDropdownData = data.map((item) => {
        return {
          label: item.name,
          value: item.name,
        };
      });
      setCustomerTypesDropdown(createDropdownData);
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      if (e.response) {
        if (e.response.status === 401) {
          dispatch(logout());
        } else {
          setMessage({
            display: true,
            severity: "error",
            message: e.response.data.message,
          });
        }
      }
      console.log(e);
    }
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      await getCustomerTypes();
    })();
  }, [getCustomerTypes]);

  const getSubscriptionPlans = useCallback(async () => {
    try {
      setLoading(true);
      if (!!invoiceForm) {
        const {
          data,
        }: {
          data: SubscriptionPlan[];
        } = await axiosInstance(
          URLS.get_subscription_plans(invoiceForm.customerType as string),
        );

        const createSubscriptionDropdownData = data.map((item) => {
          return { value: String(item.id), label: item.name };
        });

        createSubscriptionDropdownData.push({
          value: "-1",
          label: "Others",
        });

        setSubscriptionDropdown(createSubscriptionDropdownData);
        setSubscriptionPlans(data);
        setLoading(false);
      }
    } catch (e: any) {
      setLoading(false);
      if (e.response) {
        if (e.response.status === 401) {
          dispatch(logout());
        } else {
          setMessage({
            display: true,
            severity: "error",
            message: e.response.data.message,
          });
        }
      }
      console.log(e);
    }
  }, [dispatch, invoiceForm]);

  const getUsersByCustomerType = useCallback(async () => {
    try {
      setLoading(true);
      if (!!invoiceForm) {
        const { data }: { data: { id: string; name: string }[] } =
          await axiosInstance(
            URLS.get_users_by_customer_type(invoiceForm.customerType as string),
          );

        if (data) {
          const createDropdownData = data.map((item) => {
            return {
              label: item.name,
              value: item.id,
            };
          });
          setUsersDropdown(createDropdownData);
          await getSubscriptionPlans();
        }
        setLoading(false);
      }
    } catch (e: any) {
      setLoading(false);
      if (e.response) {
        if (e.response.status === 401) {
          dispatch(logout());
        } else {
          setMessage({
            display: true,
            severity: "error",
            message: e.response.data.message,
          });
        }
      }
      console.log(e);
    }
  }, [dispatch, getSubscriptionPlans, invoiceForm]);

  useEffect(() => {
    (async () => {
      if (!!invoiceForm && invoiceForm.customerType) {
        setItemData([]);
        await getUsersByCustomerType();
      }
    })();
  }, [getUsersByCustomerType, invoiceForm]);

  const validateForm = async () => {
    let invalid = false;
    const conditionalErrorState = {
      ...errorState,
      gstIn:
        invoiceForm?.customerType === "FRANCHISE" ? errorState.gstIn : false,
    };
    Object.keys(conditionalErrorState).forEach((key: string) => {
      if (conditionalErrorState[key as keyof ErrorState]) {
        invalid = true;
      }
    });
    const gstRegex = new RegExp(
      /^(0[1-9]|[1-2]\d|3[0-5])([a-zA-Z]{5}\d{4}[a-zA-Z][1-9a-zA-Z][zZ][\da-zA-Z])+$/,
    );
    if (invalid) {
      setActionMessage(true, "error", "Please enter correct values!");
    } else if (!!invoiceForm && invoiceForm.customerType !== "FRANCHISE") {
      await saveInvoice();
    } else if (
      !!invoiceForm &&
      invoiceForm.gstIn &&
      gstRegex.test(invoiceForm.gstIn)
    ) {
      await saveInvoice();
    } else {
      setActionMessage(true, "error", "Please enter correct gst number!");
    }
  };

  const saveInvoice = async () => {
    try {
      if (itemData.length === 0) {
        setActionMessage(true, "error", "Please add at least one item!");
        return;
      }
      const checkEmptyItem = itemData.find((item) => item.id === "");
      if (checkEmptyItem) {
        setActionMessage(true, "error", "Please remove blank item from table!");
        return;
      }
      setLoading(true);
      const itemBody = itemData.map((item) => {
        if (item.id === "-1") {
          return {
            amountPaid: item.amount,
            customInput: item.name,
          };
        } else {
          if (customAmount) {
            return {
              amountPaid: customAmountPaid,
              subscriptionPlanId: item.id,
            };
          }
          return { subscriptionPlanId: item.id };
        }
      });

      const body = {
        category: "",
        customerType: invoiceForm?.customerType,
        gstIn: invoiceForm?.gstIn ? invoiceForm?.gstIn : "",
        invoiceDate: invoiceForm?.invoiceDate,
        items: itemBody,
        paymentMethod: invoiceForm?.paymentMethod,
        userId: invoiceForm?.userId,
      };
      const { data } = await axiosInstance.post(URLS.generate_invoice, body, {
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(
        new Blob([data], { type: "application/pdf" }),
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "invoice.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setLoading(false);
      Navigate(routes.invoices);
    } catch (e: any) {
      setLoading(false);
      if (e.response) {
        if (e.response.status === 401) {
          dispatch(logout());
        } else {
          setMessage({
            display: true,
            severity: "error",
            message: e.response.data.message,
          });
        }
      }
      console.log(e);
    }
  };

  const handleNewRow = () => {
    setItemData([
      ...itemData,
      {
        id: "",
        name: "",
        indexId: handleIndex + 1,
        amount: "",
        discount: "",
        total: "",
      },
    ]);
    setHandleIndex(handleIndex + 1);
  };

  const handleItemCopy = (indexId: number) => {
    const findExistingItem = itemData.find((item) => item.indexId === indexId);
    if (findExistingItem) {
      setItemData([
        ...itemData,
        { ...findExistingItem, indexId: handleIndex + 1 },
      ]);
      setHandleIndex(handleIndex + 1);
    }
  };

  const handleItemDelete = (indexId: number) => {
    const updatedItemData = itemData.filter((item) => item.indexId !== indexId);
    setItemData(updatedItemData);
  };

  return (
    <Container
      maxWidth={false}
      disableGutters={true}
      sx={{
        backgroundColor: "#DEEDF7",
        padding: "20px",
        height: "auto",
      }}
    >
      {loading && <PageLoader />}
      {message.display && (
        <Notification
          isOpen={message.display}
          message={message.message}
          severity={message.severity as AlertColor}
          closeNotification={closeNotification}
        />
      )}

      <Typography variant="h6" component="h6" textTransform={"capitalize"}>
        New Invoice
      </Typography>
      <Box>
        <Grid container sx={{ m: 2 }}>
          {CreateInvoiceForm(
            invoiceForm?.customerType,
            customerTypesDropdown,
            usersDropdown,
          )
            .map((item: FormModel) => ({
              ...item,
              value: !!invoiceForm
                ? (invoiceForm[item.field as keyof InvoiceData] as
                    | string
                    | boolean)
                : "",
            }))
            .map((item: FormModel, index: number) => (
              <Grid size={{ xs: 12, lg: 4 }} key={index} padding={2}>
                <Typography fontWeight={600} fontSize={"18px"} color="#545454">
                  {item.label}
                </Typography>
                <RenderFormFields
                  value={item.value as string}
                  item={item}
                  handleChange={(
                    value: string,
                    field: string,
                    error?: { error: boolean; message: string },
                  ) => {
                    const data = {
                      [field]: value,
                    };
                    if (field === "customerType") {
                      setInvoiceForm((prevState: any) => ({
                        ...prevState,
                        ...data,
                        userId: "",
                      }));
                      setErrorState((prevState) => ({
                        ...prevState,
                        [field]: !!(error && error.error),
                        userId: true,
                      }));
                    } else {
                      setInvoiceForm((prevState: any) => ({
                        ...prevState,
                        ...data,
                      }));
                      setErrorState((prevState) => ({
                        ...prevState,
                        [field]: !!(error && error.error),
                      }));
                    }
                  }}
                />
              </Grid>
            ))}
        </Grid>

        <Box sx={{ background: "white" }} p={2} borderRadius={2}>
          <Typography variant="h6" component="h6" textTransform={"capitalize"}>
            Item Table
          </Typography>

          <Box sx={{ background: "#EBEBEB" }} borderRadius={2}>
            <Grid container sx={{ m: 2 }} p={2}>
              <Grid size={{ xs: 12, lg: 4 }}>
                <Typography fontWeight={500}>Item Details</Typography>
              </Grid>
              <Grid size={{ xs: 12, lg: 2 }}>
                <Typography fontWeight={500}>Amount</Typography>
              </Grid>
              <Grid size={{ xs: 12, lg: 2 }}>
                <Typography fontWeight={500}>Discount</Typography>
              </Grid>
              <Grid size={{ xs: 12, lg: 2 }}>
                <Typography fontWeight={500}>Net Assessable Value</Typography>
              </Grid>
              <Grid size={{ xs: 12, lg: 2 }}>
                <Typography fontWeight={500}>Actions</Typography>
              </Grid>
            </Grid>
          </Box>
          {itemData.map((item) => {
            return (
              <Grid container sx={{ m: 2 }} pl={2} pr={2} alignItems={"center"}>
                <Grid size={{ xs: 12, lg: 4 }}>
                  <RenderFormFields
                    value={item.id}
                    item={{ ...plansDropdown, options: subscriptionDropdown }}
                    handleChange={(value: string, field: string) => {
                      const data = {
                        [field]: value,
                      };

                      const findSubscriptionData = subscriptionPlans.find(
                        (subscription) => subscription.id === parseInt(data.id),
                      );
                      if (findSubscriptionData) {
                        const updatedItemData = itemData.map((value) => {
                          if (value.indexId === item.indexId) {
                            return {
                              indexId: value.indexId,
                              name: "",
                              id: data.id,
                              amount: findSubscriptionData.amount,
                              discount: findSubscriptionData.discount,
                              total: findSubscriptionData.total,
                            };
                          } else {
                            return value;
                          }
                        });
                        setItemData(
                          updatedItemData as {
                            id: string | number;
                            name: string;
                            indexId: number;
                            amount: string;
                            discount: string;
                            total: string;
                          }[],
                        );
                      } else {
                        setItemData([
                          {
                            id: "-1",
                            name: "",
                            indexId: 0,
                            amount: "",
                            discount: "",
                            total: "",
                          },
                        ]);
                      }
                    }}
                  />
                  {item.id === "-1" && (
                    <RenderFormFields
                      value={item.name}
                      item={{
                        ...customInputField,
                        placeholder: "Line Item",
                      }}
                      handleChange={(value: string) => {
                        setItemData((prevState) =>
                          prevState.map((item_value) => {
                            if (item_value.id === item.id) {
                              return {
                                ...item_value,
                                name: value,
                              };
                            } else {
                              return item_value;
                            }
                          }),
                        );
                      }}
                    />
                  )}
                </Grid>
                <Grid size={{ xs: 12, lg: 2 }} paddingRight={2}>
                  {item.id === "-1" ? (
                    <RenderFormFields
                      value={item.amount}
                      item={{
                        ...customInputField,
                        placeholder: "Amount Paid",
                      }}
                      handleChange={(value: string) => {
                        setItemData((prevState) =>
                          prevState.map((item_value) => {
                            if (item_value.id === item.id) {
                              return {
                                ...item_value,
                                amount: value,
                                discount: "0",
                                total: (parseInt(value) * 1.18).toString(),
                              };
                            } else {
                              return item_value;
                            }
                          }),
                        );
                      }}
                    />
                  ) : (
                    <Typography>{item.amount}</Typography>
                  )}
                </Grid>
                <Grid size={{ xs: 12, lg: 2 }}>
                  <Typography>{item.discount}</Typography>
                </Grid>
                <Grid size={{ xs: 12, lg: 2 }}>
                  <Typography>{item.total}</Typography>
                </Grid>
                <Grid size={{ xs: 12, lg: 2 }}>
                  <IconButton onClick={() => handleItemCopy(item.indexId)}>
                    <FileCopyIcon color="primary" />
                  </IconButton>
                  <IconButton>
                    <DeleteIcon
                      color="secondary"
                      onClick={() => {
                        handleItemDelete(item.indexId);
                      }}
                    />
                  </IconButton>
                </Grid>
              </Grid>
            );
          })}

          {subscriptionPlans.length > 0 && (
            <PrimaryButton
              fullWidth={false}
              sx={{
                color: "black",
                background: "white",
                mt: 2,
                borderRadius: "5px",
                textTransform: "capitalize",
              }}
              onClick={handleNewRow}
            >
              <AddCircleOutlinedIcon color="secondary" sx={{ mr: 1 }} />
              Add New Row
            </PrimaryButton>
          )}
        </Box>
      </Box>
      {franchise_id !== "PREPLIX" && (
        <Grid container justifyContent={"space-between"}>
          <Grid size={{ xs: 12, lg: 4 }}>
            <Stack direction={"row"} padding={2}>
              <Checkbox
                sx={styles.checkbox}
                checked={customAmount}
                onClick={() => setCustomAmount((prevState) => !prevState)}
              />
              <Typography
                variant={"body2"}
                color={"#676767"}
                alignContent={"center"}
                fontWeight={"bold"}
              >
                Invoice has a Partial Payment
              </Typography>
            </Stack>
          </Grid>
          {customAmount && (
            <Grid size={{ xs: 12, lg: 4 }} py={2}>
              <RenderFormFields
                value={customAmountPaid}
                item={{
                  ...customInputField,
                  placeholder: "Amount Paid",
                }}
                handleChange={(value: string) => {
                  setCustomAmountPaid(value);
                }}
              />
            </Grid>
          )}
        </Grid>
      )}
      <PrimaryButton
        fullWidth={false}
        color={"secondary"}
        sx={{
          mt: 2,
          borderRadius: "5px",
          textTransform: "capitalize",
        }}
        onClick={validateForm}
      >
        <PrintOutlinedIcon sx={{ mr: 1 }} />
        Save & Print
      </PrimaryButton>
    </Container>
  );
};

export default CreateInvoice;
