import { createSlice } from "@reduxjs/toolkit";

export interface ProfileState {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: string;
  state: string;
  city: string;
  pincode: string;
  about: string;
  bio: string;
  nameAsPerBankAccount: string;
  accountNumber: string;
  bank: string;
  branch: string;
  ifscCode: string;
  profileUrl: string;
  id: number | string;
}

const profileSlice = createSlice({
  name: "profileSlice",
  initialState: {
    about: "",
    accountNumber: "",
    address: "",
    bank: "",
    bio: "",
    branch: "",
    city: "",
    firstName: "",
    ifscCode: "",
    lastName: "",
    pincode: "",
    state: "",
    email: "",
    phone: "",
    nameAsPerBankAccount: "",
    profileUrl: "",
    id: "",
  } as ProfileState,
  reducers: {
    setProfile(
      state,
      action: {
        payload: ProfileState;
      },
    ) {
      state.about = action.payload.about;
      state.accountNumber = action.payload.accountNumber;
      state.address = action.payload.address;
      state.bank = action.payload.bank;
      state.bio = action.payload.bio;
      state.branch = action.payload.branch;
      state.city = action.payload.city;
      state.firstName = action.payload.firstName;
      state.ifscCode = action.payload.ifscCode;
      state.lastName = action.payload.lastName;
      state.pincode = action.payload.pincode;
      state.state = action.payload.state;
      state.email = action.payload.email;
      state.phone = action.payload.phone;
      state.nameAsPerBankAccount = action.payload.nameAsPerBankAccount;
      state.profileUrl = action.payload.profileUrl;
      state.id = action.payload.id;
    },
  },
});

export default profileSlice.reducer;

export const { setProfile } = profileSlice.actions;

export const UserProfile = (state: { profileSlice: ProfileState }) =>
  state.profileSlice;
